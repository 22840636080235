/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-04 15:58:03
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-19 10:46:39
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/AdxReportChart/targets.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    name: '流量请求',
    id: 'request'
  },
  {
    name: '广告请求',
    id: 'advRequest'
  },
  {
    name: '流量填充率',
    id: 'requestFilledRate'
  },
  {
    name: '流量填充',
    id: 'response'
  },
  {
    name: '广告填充',
    id: 'advResponse'
  },
  {
    name: '广告填充率',
    id: 'advRequestFilledRate'
  },
  {
    name: '展示',
    id: 'impress'
  },
  {
    name: '展示率',
    id: 'impressRate'
  },
  {
    name: '展请率',
    id: 'impressRequestRate'
  },
  {
    name: '点击',
    id: 'click'
  },
  {
    name: '点击率',
    id: 'clickRate'
  },
  {
    name: '媒体消耗',
    id: 'income'
  },
  {
    name: '广告收入',
    id: 'advPrice'
  },
  {
    name: '利润率',
    id: 'profitRate'
  },
  {
    name: '广告收入eCPM',
    id: 'advEcpm'
  },
  {
    name: '媒体成交eCPM',
    id: 'estimatedRevenueEcpm'
  },
  {
    name: '竞价响应eCPM',
    id: 'askPrice'
  },
  {
    name: '媒体消耗CPC',
    id: 'cpc'
  },
  {
    name: '广告成交CPC',
    id: 'advCpc'
  }
]
